import service1 from 'assets/images/Services/service1.svg'
import service2 from 'assets/images/Services/service2.svg'
import service3 from 'assets/images/Services/service3.svg'
import service4 from 'assets/images/Services/service4.svg'

export const servicesTitle = [
    {
        title: 'Разработка сайтов'
    },
    {
        title: 'Разработка мобильных приложений'
    },
    {
        title: 'Дизайн'
    }
]

export const services = [
    {
        img: service1,
        title: 'Создадим удобный и качественный сайт, хорошо продвигаемый в поисковых системах',
        services: [
            'Полностью под ключ (дизайн, текст, анализ конкурентов)',
            'Аудит текущего сайта',
            'Модернизация сайта (обновление, улучшение, прокачка)'
        ]
    },
    {
        img: service2,
        title: 'Приложение, которое понравится вашим клиентам и упростит их взаимодействие с вами',
        services: [
            'Полностью под ключ (дизайн, текст, анализ конкурентов)',
            'Аудит текущего приложения',
            'Модернизация приложения (обновление, улучшение, прокачка)'
        ]
    },
    {
        img: service4,
        title: 'Эффективный и эффектный дизайн на основе аналитики пользовательского опыта',
        services: [
            'Разработка индивидуального дизайна (дизайн, текст, аналитика конкурентов)',
            'Брендирование и айдентика'
        ]
    }
]