export const menu = [
  {
    title: "О нас",
    link: "#about_us",
  },
  {
    title: "Наши преимущества",
    link: "#benefits",
  },
  {
    title: "Услуги",
    link: "#services",
  },
  // {
  //     title: 'Наша команда',
  //     link: 'our_team'
  // },
  {
    title: "Контакты",
    link: "#get_started",
  },
];
