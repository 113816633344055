import Button from 'components/components/Button/Button';
import React from 'react';
import styles from './Main.module.scss'
import ReactTypingEffect from 'react-typing-effect';

const Main = () => {
    return (
        <div className='container'>
            <section className={`${styles.main} section_padding-disabled`}>
                <div className={styles.content}>
                    <div className={`title ${styles.center}`}>
                        <h1>Создадим <span className={styles.blue}>
                            <ReactTypingEffect
                                text={['сайт', 'программное обеспечение', 'мобильное приложение']}
                                // cursorRenderer={cursor => <h1>{cursor}</h1>}
                                speed={50}
                                eraseSpeed={50}
                                typingDelay={1000}
                            />
                            </span><br />для роста вашего бизнеса и привлечения новых клиентов</h1>
                    </div>
                    <Button />
                </div>
            </section>
        </div>
    );
}

export default Main;
