import React, { useState, useEffect } from 'react'
import styles from './Services.module.scss'
import { Waypoint } from 'react-waypoint';
import Main from './Main';
import Mobile from './Mobile';

export default function Services() {

    const [activeAnimTitle, setActiveAnimTitle] = useState(false)
    const [activeAnimBlock, setActiveAnimBlock] = useState(false)
    const [activeAnimButton, setActiveAnimButton] = useState(false)
    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth > 885) {
                setMobile(false)
            } else {
                setMobile(true)
            }
        })
        return () => {
            window.removeEventListener('resize', () => {})
        };
    }, []);
    

  return (
    <div className='container'>
        <div className={`vl-wrapper vl-item ${activeAnimTitle ? 'active' : ''}`}></div>
        <section className={styles.services} id='services'>
            <div className="title">
                <Waypoint
                    onEnter={() => (
                        setActiveAnimTitle(true)
                    )}
                    bottomOffset={200}
                />
                <h3 className={`title-location ${activeAnimTitle ? 'active' : ''}`}>КАК МЫ МОЖЕМ ПОМОЧЬ ВАМ</h3>
                <h1 className={`animated animatedFadeInUp ${activeAnimTitle ? 'fadeInUp' : ''}`}>Услуги</h1>
            </div>
            <Waypoint
                onEnter={() => (
                    setActiveAnimBlock(true)
                )}
                bottomOffset={200}
            />
            <Main 
                activeAnimBlock={activeAnimBlock}
                activeAnimButton={activeAnimButton}
                setActiveAnimButton={setActiveAnimButton}
                mobile={mobile}
            />
            <Mobile
                activeAnimBlock={activeAnimBlock}
                mobile={mobile}
            />
        </section>
    </div>
  )
}
