import item_yes from 'assets/images/Quiz/quiz_yes.svg'
import item_no from 'assets/images/Quiz/quiz_no.svg'

export const questions = [
    {
        questionText: 'У вас уже есть продукт?',
        questionOption: [
            {
                answerText: 'Да, нужна доработка',
                className: 'item_yes',
                quizImg: item_yes,
            },
            {
                answerText: 'Нет, нужна разработка с нуля',
                className: 'item_no',
                quizImg: item_no,
            }
        ]
    },
    {
        questionText: 'Выберите продукты:',
        questionOption: [
            {
                answerText: 'Сайт',
                type: 'checkbox',
                name: "product",
                value: "Сайт",
            },
            {
                answerText: 'Мобильное приложение',
                type: 'checkbox',
                name: "product",
                value: "Мобильное приложение",
            },
            {
                answerText: 'Дизайн',
                type: 'checkbox',
                name: "product",
                value: "Дизайн",
            },
            {
                answerText: 'Не знаю, нужна консультация',
                type: 'checkbox',
                name: "product",
                value: "Не знаю, нужна консультация",
                checked: 'true'
            },
        ]
    },
    {
        questionText: 'Какой бюджет проекта?',
        questionOption: [
            {
                answerText: 'До 100’000 ₽',
                type: 'radio',
                name: "budget",
                value: "До 100’000 ₽",
            },
            {
                answerText: '100’000–250’000 ₽',
                type: 'radio',
                name: "budget",
                value: "100’000–250’000 ₽",
            },
            {
                answerText: '250’000–500’000 ₽',
                type: 'radio',
                name: "budget",
                value: "250’000–500’000 ₽",
            },
            {
                answerText: '500’000–1’000’000 ₽',
                type: 'radio',
                name: "budget",
                value: "500’000–1’000’000 ₽",
            },
            {
                answerText: 'Более 1’000’000 ₽',
                type: 'radio',
                name: "budget",
                value: "Более 1’000’000 ₽",
            },
            {
                answerText: 'Бюджет не определён',
                type: 'radio',
                name: "budget",
                value: "Бюджет не определён",
                checked: 'true'
            }
        ]
    },
    {
        questionOption: [
            {
                answerText: 'Имя или организация',
                type: 'text',
                name: 'name',
                placeholder: 'Иван, ООО “Иванов”'
            },
            {
                answerText: 'Почта',
                type: 'email',
                name: 'email',
                placeholder: 'ivan@innovide.ru'
            },
            {
                answerText: 'Телефон',
                type: 'text',
                name: 'tel',
                placeholder: '+7-297-934-33-44'
            }
        ]
    }
]