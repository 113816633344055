import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styles from './Button.module.scss'

const Button = ({ classes, active }) => {
    return (
        <div className={`${styles.center} ${classes?.join(' ')} ${active ? 'fadeIn' : ''}`}>
            <a href='#get_started' className={styles.link}>
                <button className={styles.button} type='button'>Обсудить проект</button>
            </a>
        </div>
    );
}

export default Button;
