import React, { useState, useRef } from "react";
import styles from "./Quiz.module.scss";
import choiceImg from "assets/images/Quiz/choice.svg";

const FirstQuestion = ({
  questions,
  currentQuestion,
  choiceActiveYes,
  setChoiceActiveYes,
  choiceActiveNo,
  setChoiceActiveNo,
  itemChoice,
  quizAnswer,
}) => {
  let firstAnswer = "";
  function checkChoice(idx) {
    if (idx === 0) {
      setChoiceActiveYes(true);
      setChoiceActiveNo(false);
      firstAnswer = "Доработка";
    } else {
      setChoiceActiveYes(false);
      setChoiceActiveNo(true);
      firstAnswer = "Разработка с нуля";
    }
    quizAnswer.type = firstAnswer;
  }

  return (
    <ul ref={itemChoice} className={styles.list}>
      {questions[currentQuestion].questionOption.map((item, idx) => (
        <li
          key={`quiz item ${idx}`}
          className={styles["item"]}
          onClick={() => checkChoice(idx)}
        >
          <img
            className={`${styles.img} ${styles["img_yes"]}`}
            src={`${item.quizImg}`}
            alt={`${item.className}`}
          />
          {idx === 0 && choiceActiveYes ? (
            <img
              className={`${styles.item_choice} ${styles.active}`}
              src={choiceImg}
              alt="choice"
            />
          ) : (
            false
          )}
          {idx === 1 && choiceActiveNo ? (
            <img
              className={`${styles.item_choice} ${styles.active}`}
              src={choiceImg}
              alt="choice"
            />
          ) : (
            false
          )}
          <p>{item.answerText}</p>
        </li>
      ))}
    </ul>
  );
};

export default FirstQuestion;
