import React, { useState } from 'react';
import styles from './Benefits.module.scss'
import Pic1 from 'assets/images/Benefits/benefit-1.png'
import Pic2 from 'assets/images/Benefits/benefit-2.png'
import Pic3 from 'assets/images/Benefits/benefit-3.png'
import { Waypoint } from 'react-waypoint';

const Benefits = () => {
    const [activeAnim, setActiveAnim] = useState(false)
    const [activeAnimOne, setActiveAnimOne] = useState(false)
    const [activeAnimTwo, setActiveAnimTwo] = useState(false)
    const [activeAnimThree, setActiveAnimThree] = useState(false)
    return (
        <div className='container'>
            <div className={`vl-wrapper vl-item ${activeAnim ? 'active' : false}`}></div>
            <section className={styles.benefits} id='benefits'>
                <div className="title">
                <Waypoint
                    onEnter={() => (
                        setActiveAnim(true)
                    )}
                    bottomOffset={200}
                />
                    <h3 className={`title-location ${activeAnim ? 'active' : false}`}>ПОЧЕМУ МЫ?</h3>
                    <h1 className={`animated animatedFadeInUp ${activeAnim ? 'fadeInUp' : false}`}>Наши преимущества</h1>
                </div>
                <ul className={styles['list-benefits']}>
                    <Waypoint
                        onEnter={() => (
                            setActiveAnimOne(true)
                        )}
                        bottomOffset={200}
                    />
                    <li className={`${styles.item} ${styles['item_flex-start']} animated animatedFadeInLeft ${activeAnimOne ? 'fadeInLeft' : false}`}>
                        <div className={styles.img}>
                            <img src={Pic1} alt="Pic-1" />
                        </div>
                        <div className="wrapper">
                            <h3>Вникаем в ваш бизнес</h3>
                            <p>Выделим основные преимущества и выгоды для клиента</p>
                        </div>
                    </li>
                    <Waypoint
                        onEnter={() => (
                            setActiveAnimTwo(true)
                        )}
                        bottomOffset={200}
                    />
                    <li className={`${styles.item} ${styles['item_flex-end']} animated animatedFadeInRight ${activeAnimTwo ? 'fadeInRight' : false}`}>
                        <div className={styles.img}>
                            <img src={Pic2} alt="Pic-2" />
                        </div>
                        <div className="wrapper">
                            <h3>Анализ целевой аудитории</h3>
                            <p>Выделим основные преимущества и выгоды для клиента</p>
                        </div>
                    </li>
                    <Waypoint
                        onEnter={() => (
                            setActiveAnimThree(true)
                        )}
                        bottomOffset={200}
                    />
                    <li className={`${styles.item} ${styles['item_flex-start']} animated animatedFadeInLeft ${activeAnimThree ? 'fadeInLeft' : false}`}>
                        <div className={styles.img}>
                            <img src={Pic3} alt="Pic-3" />
                        </div>
                        <div className="wrapper">
                            <h3>Продумаем дизайн проекта</h3>
                            <p>От архитектуры, до логики — всё сложное делаем простым</p>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    );
}

export default Benefits;
