import React, { useState } from 'react';
import about_us from 'assets/images/AboutUs/about_us.svg';
import styles from './AboutUs.module.scss'
import { Waypoint } from 'react-waypoint';


const AboutUs = () => {
    const [activeAnim, setActiveAnim] = useState(false)
    return (
        <div className="container">
            <div className={`vl-wrapper vl-item_start`}></div>
            <section className={`${styles['about-us']} animated animatedFadeInUp ${activeAnim ? 'fadeInUp' : false}`} id="about_us">
            <Waypoint
                onEnter={() => (
                    setActiveAnim(true)

                )}
                bottomOffset={200}
            />
                <div className="wrapper">
                    <div className="title">
                        <h1><span className={styles.blue}>Innovide</span> — превратит<br />вашу идею в рабочий IT-продукт</h1>
                    </div>
                    <div className={`subtitle ${styles.subtitle}`}>
                        <p>Создадим с нуля, доработаем действующий</p>
                    </div>
                </div>
                <div className={styles.img}>
                    <img src={about_us} alt="about us" />
                </div>
            </section>
        </div>
    );
}

export default AboutUs;
