import React from 'react';
import styles from './Header.module.scss'

const Mobile = ({ visible, setVisible }) => {
    const visibleBurgerMenu = () => {
        if (visible) {
            setVisible(false)
            document.body.style.overflow = 'visible'
            return
        }
        document.body.style.overflow = 'hidden'
        setVisible(true)
    }
    return (
        <div className={`${styles['burger-btn']} ${visible ? styles.active : ''}`} onClick={visibleBurgerMenu}>
        </div>
    );
}

export default Mobile;
