import React, { useRef, useEffect } from 'react'
import styles from './Quiz.module.scss'

export default function SecondThirdQuestions({ questions, currentQuestion }) {
    let checkedRef = useRef(null)

    return (
        <form className={`${styles.form} ${styles[`form-product`]}`}>
            {currentQuestion === 1 && (

                questions[currentQuestion].questionOption.map((item, idx) => (
                    <label key={`label key ${idx}`} className={styles.label}>
                        <input
                            className={`${styles.input} checkbox_isChecked`}
                            type={item.type}
                            name={item.name}
                            value={item.value}
                        />
                        <span className={styles[`${item.type}`]}></span>
                        <span>{item.answerText}</span>
                    </label>
                ))

            )}
            {currentQuestion === 2 && (
                questions[currentQuestion].questionOption.map((item, idx) => (
                    <label key={`label key ${idx}`} className={styles.label}>
                        <input
                            className={`${styles.input} radio_isChecked`}
                            type={item.type}
                            name={item.name}
                            value={item.value}
                        />
                        <span className={styles[`${item.type}`]}></span>
                        <span>{item.answerText}</span>
                    </label>
                ))
            )}
        </form>
    )
}
