import React from 'react';
import styles from './QuizPopup.module.scss'
import done from 'assets/images/Quiz/product-done.svg'
import exit from 'assets/images/Quiz/exit.svg'

const QuizPopup = ({ activePopup, resetQuiz }) => {
    if (activePopup) {
        // document.body.style.overflow = 'hidden'
    } else {
        // document.body.style.overflow = 'visible'
    }
    return (
        <div className={activePopup ? `${styles.popup} ${styles.active}` : styles.popup} onClick={() => resetQuiz()}>
            <div className={activePopup ? `${styles.content} ${styles.active}` : styles.content} onClick={(e) => e.stopPropagation()}>
                <div className='wrapper'>
                    <div className={styles.title}>
                        <p>Заявка отправлена!</p>
                    </div>
                    <div className={styles.subtitle}>
                        <p>Мы свяжемся с вами в ближайшее время.</p>
                    </div>
                </div>
                <img src={done} alt="done" className={styles['done-img']} />
                <img className={styles['exit-img']} src={exit} alt="exit" onClick={() => resetQuiz()} />
            </div>
        </div>
    );
}

export default QuizPopup;
