import React, { useState, useEffect } from 'react';
import logo from 'assets/images/logo_header.svg'
import styles from './Header.module.scss'
import Mobile from './Mobile';
import { menu } from '../../../data/Menu/Menu'


const Header = ({ visible, setVisible }) => {
    const [direction, setDirection] = useState('top')
    let oldScrollY = 0;
    const controlDirection = () => {
        let scrolled = window.scrollY
        if (scrolled > oldScrollY && scrolled > 100) {
            setDirection('down');
        }
        if (scrolled < oldScrollY && scrolled > 300) {
            setDirection('up');
        }
        if (scrolled == 0) {
            setDirection('top');
        }
        oldScrollY = window.scrollY
    }

    useEffect(() => {
        window.addEventListener('scroll', controlDirection);
        return () => {
            window.removeEventListener('scroll', controlDirection);
        };
    }, []);

    return (
        <header className={`${styles.header} ${direction == 'down' ? `${styles.out}` : ''} ${direction == 'up' ? `${styles.fixed}` : ''} ${visible ? styles['fixed_disabled'] : ''}`}>
            <div className={styles.container}>
                <a href="#" className={styles.link}><img className={styles.logo} src={logo} alt="logo" /></a>
                <ul className={styles.nav}>
                    {menu.map((item, idx) => (
                        <li key={`menu item ${idx}`} className={styles['list-item']}>
                            <a href={item.link} className={styles.link}>{item.title}</a>
                        </li>
                    ))}
                    <li className={`${styles.langs} ${styles['list-item']} hidden`}><div><a href="#" className='buttonEn'>EN</a> | <a href="#" className='buttonRu'>RU</a></div></li>
                </ul>
                <Mobile visible={visible} setVisible={setVisible} />
            </div>
        </header>
    );
}

export default Header;
