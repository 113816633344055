import React from 'react';
import styles from './BurgerMenu.module.scss'
import whatsapp from 'assets/images/whatsapp.svg'
import viber from 'assets/images/viber.svg'
import telegram from 'assets/images/telegram_white.svg'
import { menu } from '../../../data/Menu/Menu'


const BurgerMenu = ({ visible, setVisible }) => {
    const goToLink = () => {
        setVisible(false)
        document.body.style.overflow = 'visible'
    }
    return (
        <div className={`${styles['burger-menu']} ${visible ? styles.active : ''}`}>
            <ul className={`${styles.nav}`}>
                {menu.map((item, idx) => (
                    <li key={`menu item ${idx}`} className={styles['list-item']} onClick={goToLink}>
                        <a href={item.link} className={styles.link}>{item.title}</a>
                    </li>
                ))}
                <li className={`${styles.langs} ${styles['list-item']} hidden`}><div><a href="#" className='buttonEn'>EN</a> | <a href="#" className='buttonRu'>RU</a></div></li>
            </ul>
            <div className={styles.social}>
                <div className={styles.whatsapp}>
                    <a className={styles.link} href="" target="_blank">
                        <img src={whatsapp} alt="inst" />
                    </a>
                </div>
                <div className={styles.viber}>
                    <a className={styles.link} href="" target="_blank">
                        <img src={viber} alt="inst" />
                    </a>
                </div>
                <div className={styles.telegram}>
                    <a className={styles.link} href="https://t.me/innovide" target="_blank">
                        <img src={telegram} alt="telegram" />
                    </a>
                </div>
            </div>
        </div >
    );
}

export default BurgerMenu;
