import React from "react";
import styles from "./Footer.module.scss";
import logo from "assets/images/logo_footer.svg";
import { menu } from "../../../data/Menu/Menu";
import telegram from "assets/images/telegram_white.svg";
import whatsapp from "assets/images/whatsapp.svg";
import viber from "assets/images/viber.svg";
import poly from "assets/docs/poly.pdf";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={`wrapper ${styles["grid-wrapper"]}`}>
        <nav className={styles.nav}>
          <div className={styles.logo_footer}>
            <a className={styles.link} href="#">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <ul className={styles.menu}>
            {menu.map((item, idx) => (
              <li key={`menu item ${idx}`} className={styles["item"]}>
                <a href={item.link} className={styles.link}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.social}>
            <a
              href="https://api.whatsapp.com/send?phone=79373888107"
              target="_blank"
            >
              <img src={whatsapp} alt="whatsapp" />
            </a>
            <a href="" target="_blank">
              <img className={styles.viber} src={viber} alt="viber" />
            </a>
            <a href="https://t.me/durel7" target="_blank">
              <img src={telegram} alt="telegarm" />
            </a>
          </div>
        </nav>
        <div className={styles.info}>
          <div className="copyright">
            <p>© Copyright 2022, ООО«Инновайд». Все права защищены.</p>
          </div>
          <div className="policy">
            <p>
              <a href={poly} target="_blank">
                Политика защиты и обработки персональных данных
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
